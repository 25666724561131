
import { Component, Prop, Emit, Watch } from 'vue-property-decorator';
import VTable from '../../shared/table/VTable.vue';
import VColumnSelect from '@/components/shared/table/VColumnSelect.vue';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import optionHeaders from './headers';
import { OptionArticle } from '@/interfaces/models/OptionArticle';
import { Permission } from '@/enums/Permission';
import { Venue } from '@/interfaces/models/Venue';
import { mixins } from 'vue-class-component';
import { EditArticle as EditArticleMixin } from '@/mixins/EditArticle';
import { OptionGroup } from '@/interfaces/models/OptionGroup';
import typeSortByPrice from './sortByPrice';
import typeSortByVisibility from './sortByVisibility';
import VItemSelect from '@/components/shared/table/VItemSelect.vue';
import _ from 'lodash';
import { Category } from '@/interfaces/models/Category';
import { Tag } from '@/interfaces/models/Tag';
import { decimalAdjust } from '@/util/helper';
import i18n from '@/i18n';
import FormatPriceInObject from '@/mixins/FormatPriceInObject';
import { ExternalOrderProvider } from '@/enums/ExternalOrderProvider';
import { ExternalOrderProvidersArticleSettings } from '@/interfaces/models/ExternalOrderProvidersArticleSettings';

type ExternalOrderProviderKeys = keyof ExternalOrderProvidersArticleSettings;

@Component({
  components: { VColumnSelect, VTable, VItemSelect },
})
export default class OptionArticleTable extends mixins(EditArticleMixin, FormatPriceInObject) {
  @Prop({ type: Array, required: true }) public items!: OptionArticle[];
  @Prop({ type: String, default: '6' }) public offset!: number;
  @Prop({ type: Object, default: {} }) public option!: OptionGroup;
  @Prop({ type: Array, required: true }) public categories!: Category[];
  @Prop({ type: Array, required: false }) public tags!: Tag[];

  readonly tableKey: string = 'optionArticleTableColumns';
  readonly externalOrderProvider = ExternalOrderProvider;

  public sourceHeaders = optionHeaders;
  public headers: ColumnHeader[] = [];
  public sourceTypePrice = typeSortByPrice;
  public sourceTypeVisibility = typeSortByVisibility;
  public search = '';
  public number = '';
  //public externalId = '';
  public category: string | null = '';

  public editDialog: any = {
    isVisible: false,
    value: null,
    name: '',
    original: null,
  };

  public mounted() {
    if (!this.venue) return;
    this.serializeHeaders();
  }

  private serializeHeaders() {
    const shouldIncludeHeader = (headerValue: string): boolean => {
      if (
        (headerValue === 'uberEatsTakeawayEnabled' || headerValue === 'uberEatsDeliveryEnabled') &&
        !this.isEnabledUber()
      ) {
        return false;
      }
      if ((headerValue === 'woltVisibility' || headerValue === 'woltActive') && !this.isEnabledWolt()) {
        return false;
      }
      if ((headerValue === 'jetTakeawayEnabled' || headerValue === 'jetDeliveryEnabled') && !this.isEnabledJet()) {
        return false;
      }
      return true;
    };

    this.sourceHeaders = this.sourceHeaders.filter((c: ColumnHeader) => shouldIncludeHeader(c.value));
    this.headers = this.headers.filter((c: ColumnHeader) => shouldIncludeHeader(c.value));
  }

  get sorted() {
    return this.items;
  }

  public handleAsset(event: { venue: Venue; asset: string }) {
    if (this.option && event.venue && event.asset) {
      this.updateOptionArticleAsset(this.option._id, event.venue._id, event.asset);
    }
  }

  public canToggleActivation(val: boolean) {
    if (val) {
      return this.$can(Permission.OPTION_ARTICLE_DEACTIVATE);
    }
    return this.$can(Permission.OPTION_ARTICLE_ACTIVATE);
  }

  public canToggleVisbility(val: boolean) {
    if (val) {
      return this.$can(Permission.OPTION_ARTICLE_VISIBLE);
    }
    return this.$can(Permission.OPTION_ARTICLE_HIDE);
  }

  public onChangeSearch = _.debounce((searchTerm: any) => {
    this.$emit('search', { search: searchTerm });
  }, 500);

  public handleSortPriceType(event: string) {
    this.$emit('search', { priceSort: event });
  }

  public handleSortVisibilityType(event: string | undefined) {
    this.$emit('search', { filterVisible: event });
  }

  public editModal(data: { header: ColumnHeader; item: any }) {
    if (data.item) {
      this.editDialog.isVisible = true;
      this.editDialog.name = data.header.value;
      this.editDialog.original = { ...data.item };
      this.editDialog.value = this.getEditDialogValue(data.header.value, data.item);
      this.editDialog.cell = data.header.value;
    }
  }

  private getEditDialogValue(headerValue: string, item: any): any {
    const localized = (value: string) => this.$options.filters!.localized(value);
    const decimal = (value: any) => value?.$numberDecimal || value || '';

    if (Object.values(ExternalOrderProvider).includes(headerValue as ExternalOrderProvider)) {
      const price = (item as OptionArticle).externalOrderProviderSettings?.find(
        (i) => i.provider === headerValue,
      )?.price;
      return decimal(price ?? 0);
    }
    const valueHandlers: { [key: string]: (value: any) => any } = {
      name: localized,
      description: localized,
      price: decimal,
      taxTakeaway: decimal,
      taxInside: decimal,
      'priceByType.preorder.delivery': (item) => decimal(item?.priceByType?.preorder?.delivery),
      'priceByType.preorder.takeAway': (item) => decimal(item?.priceByType?.preorder?.takeAway),
    };

    const handler = valueHandlers[headerValue];
    return handler ? handler(item[headerValue]) : item[headerValue];
  }

  public taxTakeawayToPercent(tax: { $numberDecimal: number }) {
    if (tax.hasOwnProperty('$numberDecimal')) {
      if (tax && tax?.$numberDecimal?.toString() === '-1') {
        return '';
      }
      if (tax && tax.$numberDecimal) {
        const taxNumber = Number(tax.$numberDecimal);
        return `${decimalAdjust('round', taxNumber * 100, -1)}%`;
      }
    } else {
      const taxNumber = Number(tax);
      return tax && tax.toString() !== '-1' && typeof tax === 'string'
        ? `${decimalAdjust('round', taxNumber * 100, -1)}%`
        : '';
    }

    return '-1';
  }

  get taxes() {
    return [
      { value: '0.20', text: '20%' },
      { value: '0.19', text: '19%' },
      { value: '0.16', text: '16%' },
      { value: '0.10', text: '10%' },
      { value: '0.085', text: '8.5%' },
      { value: '0.081', text: '8.1%' },
      { value: '0.07', text: '7%' },
      { value: '0.077', text: '7.7%' },
      { value: '0.055', text: '5.5%' },
      { value: '0.026', text: '2.6%' },
      { value: '0.025', text: '2.5%' },
      { value: '0.021', text: '2.1%' },
      { value: '0', text: '0%' },
      { value: '-1', text: 'default' },
    ];
  }

  public saveCellValue() {
    const { name, value, original } = this.editDialog;

    if (Object.values(ExternalOrderProvider).includes(name)) {
      const { externalOrderProviderSettings } = original || {};

      const updatedSettings =
        externalOrderProviderSettings?.map((item: ExternalOrderProvidersArticleSettings) =>
          item.provider === name ? { ...item, price: value } : item,
        ) || [];

      const providerExists = externalOrderProviderSettings?.some(
        (item: ExternalOrderProvidersArticleSettings) => item.provider === name,
      );

      if (!providerExists) {
        updatedSettings.push({ provider: name, price: value });
      }

      this.$emit('editedExternalOrderProvider', { original, update: updatedSettings });
      this.stopEditing();
      return;
    }

    const data = {
      original,
      field: name,
      value,
    };

    switch (name) {
      case 'price':
        this.updatePriceField(data);
        break;

      case 'name':
      case 'description':
        this.updateI18nField(data, name);
        break;
    }

    this.$emit('edited', data);
    this.stopEditing();
  }

  private updatePriceField(data: any) {
    const arr = ['value'];
    arr.forEach((type) => {
      const res = this.deepSearchItems(data, type, (k: any, v: any) => v?.includes?.(','));
      res.forEach((obj: any) => {
        obj[type] = obj[type].replaceAll(',', '.');
      });
    });
  }

  private updateI18nField(data: any, field: string) {
    const original = { ...this.editDialog.original[field] };
    original[i18n.locale] = this.editDialog.value;
    data.value = original;
  }

  public stopEditing() {
    this.editDialog.isVisible = false;
    this.editDialog.value = null;
    this.editDialog.original = null;
    this.editDialog.name = '';
  }

  public filterTagsByIds(tags: any) {
    if (!this.tags) return '';
    let filterTags: Tag[] = [];

    if (this.isTag(tags && tags[0] ? tags[0] : {})) {
      filterTags = this.tags.filter((tag: Tag) => !!tags?.find((selectedTag: Tag) => selectedTag._id === tag._id));
    } else {
      filterTags = this.tags.filter((tag: Tag) => !!tags?.find((tagId: string) => tagId === tag._id));
    }
    return filterTags
      .map((tag: Tag) => {
        return this.$options?.filters!.localized(tag.name);
      })
      .toString();
  }

  public isEnabledWolt() {
    return !!this.venue?.externalOrderProviders?.wolt?.enabled;
  }
  public isEnabledUber() {
    return !!this.venue?.externalOrderProviders?.uberEats?.enabled;
  }
  public isEnabledJet() {
    return !!this.venue?.externalOrderProviders?.jet?.enabled;
  }

  private updateProviderSettings(
    item: OptionArticle,
    providerName: ExternalOrderProvider,
    key: string,
    initialValue = false,
  ) {
    const externalOrderProviderSettings =
      item?.externalOrderProviderSettings?.map((provider) => {
        if (provider.provider === providerName) {
          return {
            ...provider,
            [key]: key in provider ? !provider[key as ExternalOrderProviderKeys] : initialValue,
          };
        }
        return provider;
      }) || [];

    if (externalOrderProviderSettings.findIndex((p) => p.provider === providerName) === -1) {
      externalOrderProviderSettings.push({ provider: providerName, [key]: initialValue });
    }

    this.$emit('editedExternalOrderProvider', { original: item, update: externalOrderProviderSettings });
  }

  public getProviderValue(item: OptionArticle, providerName: ExternalOrderProvider, key: string) {
    if (!item.externalOrderProviderSettings) return false;

    const provider = item.externalOrderProviderSettings.find((i) => i.provider === providerName);

    if (!(key in (provider || {}))) {
      return true;
    }

    return provider?.[key as ExternalOrderProviderKeys];
  }

  private isTag(obj: any): obj is Tag {
    return typeof obj === 'object' && obj !== null && '_id' in obj;
  }

  @Watch('category')
  @Emit('category')
  public onCategoryChange() {
    this.search = '';
    return this.category ? this.category : null;
  }
}
